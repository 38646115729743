import React from 'react'
import PropTypes from 'prop-types'

const DrawerView = ({ isVisible, children }) => (
  <div
    className={`c-drawer ${isVisible ? `is-visible` : ``}`}
  >
    {children}
  </div>
)

DrawerView.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
}

export default DrawerView
