// Libraries
import React, { Component, Fragment } from 'react'
import { withCookies } from 'react-cookie'
import classNames from 'classnames'
import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'
import TwitterPixel from 'react-twitter-conversion-tracker'

const Overlay = ({ clickHandler }) => {
  /* eslint-disable */
  return (
    <span
      onClick={ clickHandler }
      className='c-cookie-consent__overlay'
    />
  )
  /* eslint-enable */
}

class CookieConsent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      cookiesAllowed: false,
      open: false,
    }
  }

  componentDidMount = () => {
    this.setState({
      cookiesAllowed: this.retrieveCookie('___stakhanovCookiesAllowed') || false,
      open: !this.retrieveCookie('___stakhanovCookieMessageSeen'),
    })
  }

  retrieveCookie = (cookieName) => {
    const { cookies } = this.props

    if (cookies.get(cookieName)) {
      return JSON.parse(cookies.get(cookieName))
    } else {
      // We're only using truthy/falsey values in our
      // cookies, so fine to return false if it doesn't
      // exist
      return false
    }
  }

  getCookieExpiryDate = () => {
    const date = new Date()
    date.setDate(date.getDate() + 30)
    return date
  }

  acceptCookies = () => {
    this.setState({
      cookiesAllowed: true,
    })

    const { cookies } = this.props
    cookies.set('___stakhanovCookiesAllowed', true, {
      expires: this.getCookieExpiryDate(),
      path: '/',
    })

    this.closeMessage()
  }

  updateCookieMessageSeenCookie = () => {
    const { cookies } = this.props
    cookies.set('___stakhanovCookieMessageSeen', true, {
      expires: this.getCookieExpiryDate(),
      path: '/',
    })
  }

  rejectCookies = () => {
    this.setState({
      cookiesAllowed: false,
    })

    this.closeMessage()
    if (window.gaOptout) {
      window.gaOptout()
    }
  }

  closeMessage = () => {
    this.setState({
      open: false,
    })

    this.updateCookieMessageSeenCookie()
  }

  renderGa () {
    ReactGA.initialize('UA-10052015-3')

    if (window) {
      ReactGA.pageview(window.location.pathname + window.location.search)
    }
  }

  renderFb () {
    if (window) {
      ReactPixel.init('950754295126522')
      ReactPixel.pageView()
    }
  }

  renderTwitter () {
    if (window) {
      TwitterPixel.init('o2v91')
      TwitterPixel.pageView()
    }
  }

  render () {
    const statefulClassNames = classNames('c-cookie-consent', {
      'is-open': this.state.open,
    })

    return (
      <Fragment>
        <aside className={ statefulClassNames }>
          <div className='c-cookie-consent__section'>
            <p className='u-fw-bold'>This site uses cookies to store information on your computer.</p>
            <p>Some of these cookies are essential, while others help us to improve your experience by providing insights into how the site is being used.</p>
            <button
              className='c-btn'
              onClick={ this.acceptCookies }>Accept Cookies</button>
          </div>
          <button className='c-cookie-consent__close'>
            <span className='u-hide-visually'>Reject cookies</span>
          </button>
        </aside>
        { this.state.open && <Overlay clickHandler={ this.rejectCookies } /> }
        { this.state.cookiesAllowed && this.renderGa() }
        { this.state.cookiesAllowed && this.renderFb() }
        { this.state.cookiesAllowed && this.renderTwitter() }
      </Fragment>
    )
  }
}

export default withCookies(CookieConsent)
