import React from 'react'
import PropTypes from 'prop-types'

import View from './NavigationView'

const Navigation = ({ items, classNamePrefix, parentClassName, menuClassName }) => (
  <View
    items={items}
    classNamePrefix={classNamePrefix}
    parentClassName={parentClassName}
    menuClassName={menuClassName}
  />
)

Navigation.propTypes = {
  items: PropTypes.array.isRequired,
  classNamePrefix: PropTypes.string.isRequired,
  parentClassName: PropTypes.string,
  menuClassName: PropTypes.string
}

export default Navigation
