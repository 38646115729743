import React from 'react'

import { TwitterIcon, FacebookIcon, InstagramIcon } from 'Icons'

const icons = {
  facebook: <FacebookIcon />,
  twitter: <TwitterIcon />,
  instagram: <InstagramIcon />
}

const SocialIconsItem = ({ service, linkTo, label, className }) => (
  <a
    href={linkTo}
    target='_blank'
    rel='noopener noreferrer'
    className={`c-social-icon c-social-icon--${service} ${className || ``}`}
    aria-label={label}
  >
    {icons[service]}
  </a>
)

export default SocialIconsItem
