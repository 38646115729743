import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import View from './FooterView'

const Footer = () => {
  const { mainNavData } = useStaticQuery(
    graphql`
      query {
        mainNavData: allMarkdownRemark(
          filter: {
            frontmatter: {
              title: {
                eq: "Main Navigation"
              }
            }
          }
        ) {
          edges {
            node {
              frontmatter {
                items {
                  text
                  url
                }
              }
            }
          }
        }
      }
    `
  )

  const mainNavItems = mainNavData.edges[0].node.frontmatter.items.map((item) => {
    if (item.url.startsWith('../')) {
      item.url = item.url.substring(2)
    }
    return item
  })

  return (
    <View
      navItems={mainNavItems}
    />
  )
}

export default Footer
