import React, { useState } from 'react'
import { Index } from 'elasticlunr'

import View from './SearchView'

const SearchQuery = ({ searchIndex }) => {
  const [query, setQuery] = useState(``)
  const [results, setResults] = useState([])

  const handleSearch = (event) => {
    const query = event.target.value
    setQuery(query)

    setResults(
      index
        .search(query, {
          expand: true
        })
        .map(({ ref }) => index.documentStore.getDoc(ref))
    )
  }

  const index = Index.load(searchIndex)

  return (
    <View
      results={results}
      query={query}
      handleSearch={handleSearch}
    />
  )
}

export default SearchQuery
