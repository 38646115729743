import React from 'react'

const DrawerTrigger = ({ handleClick, className, children }) => (
  <button
    className={`c-drawer-trigger ${className || ``}`}
    onClick={handleClick}
  >
    <span
      className='u-hide-visually'
    >
      Toggle Menu
    </span>
    {children}
  </button>
)

export default DrawerTrigger
