import React, { useContext } from 'react'
import classNames from 'classnames'

import { AudioPlayerContext } from '../AudioPlayer/AudioPlayerContext'

import FooterTop from './FooterTop'
import FooterBottom from './FooterBottom'

const FooterView = ({ navItems }) => {
  /**
   * Watch the audio player state to see if it's open
   */
  const [state] = useContext(AudioPlayerContext)
  const playerIsOpen = state ? state.playerIsOpen : false

  return (
    <footer
      className={classNames('c-footer', { 'c-footer--player-open': playerIsOpen })}
    >
      <FooterTop />
      <FooterBottom
        navItems={navItems}
      />
    </footer>
  )
}

export default FooterView
