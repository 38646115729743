import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import SearchWrapper from './SearchWrapper'

const Search = () => (
  <StaticQuery
    query={SearchIndexQuery}
    render={data => (
      <SearchWrapper
        searchIndex={data.siteSearchIndex.index}
      />
    )}
  />
)

export default Search

const SearchIndexQuery = graphql`
  query SearchIndexQuery {
    siteSearchIndex {
      index
    }
  }
`
