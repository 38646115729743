import React, { useState } from 'react'
import { Link } from 'gatsby'
import { ReactComponent as SearchIcon } from 'assets/img/svg/search.svg'

const SearchView = ({ results, query, handleSearch }) => {
  const searchInputRef = React.createRef()
  const [isVisible, setVisible] = useState(false)

  const toggleVisible = () => {
    setVisible(!isVisible)
  }

  /**
   * 2 hacky 4 me
   * Focus the input once it's visible, but also wait 300ms.
   * Also for some reason, `searchInputRef.current` only has a value once, so
   * make sure to check it has a value
   */
  const focusInput = () => {
    if (isVisible) {
      setTimeout(() => {
        if (searchInputRef.current) {
          searchInputRef.current.focus()
        }
      }, 300)
    }
  }

  focusInput()

  return (
    <div className='c-search'>
      {/* eslint-disable */}
      <div
        className='c-search__icon'
        onClick={toggleVisible}
      >
      {/* eslint-enable */}
        <SearchIcon />
        <h3
          className='u-hide-visually'
        >
          Search
        </h3>
      </div>
      <div
        className={`c-search__fields ${isVisible ? `is-visible` : ``}`}
      >
        <input
          type='text'
          value={query}
          onChange={handleSearch}
          placeholder='Search...'
          className='c-search__input'
          ref={searchInputRef}
          aria-label='Search'
        />
        <ul className='c-search__results'>
          {
            results.map(result =>
              <li
                key={result.id}
                className='c-search__results-item'
              >
                <Link
                  to={result.slug.replace('/pages/', '/').replace('/generic/', '/')}
                  className='c-search__results-link'
                >
                  {result.title}
                </Link>
              </li>
            )
          }
        </ul>
      </div>
    </div>
  )
}

export default SearchView
