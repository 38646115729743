import React from 'react'
import { Link } from 'gatsby'

const NavigationItem = ({ text, url, classNamePrefix }, key) => (
  <li
    key={key}
    className={`${classNamePrefix}__menu-item`}
  >
    <Link
      to={url}
      className={`${classNamePrefix}__menu-link o-action-link`}
      activeClassName='is-active'
      partiallyActive
    >
      {text}
    </Link>
  </li>
)

export default NavigationItem
