/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'

import View from './LayoutView'

const Layout = ({ children }) => (
  <View>
    {children}
  </View>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
