import React from 'react'

import SocialIcons from 'SocialIcons'

const twitter = {
  url: `https://twitter.com/stakpod`
}

const instagram = {
  url: `https://instagram.com/stakpod`
}

const FooterTop = () => (
  <div className='c-footer-top'>
    <div
      className='o-container'
    >
      <SocialIcons
        instagram={instagram}
        twitter={twitter}
      />
    </div>
  </div>
)

export default FooterTop
