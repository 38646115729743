import React, { Fragment } from 'react'

import SocialIconsItem from './SocialIconsItem'

const SocialIconsView = ({ facebook, twitter, instagram }) => (
  <Fragment>
    {
      facebook &&
        <SocialIconsItem
          service={`facebook`}
          linkTo={facebook.url}
          label='Stak on Facebook'
        />
    }
    {
      twitter &&
        <SocialIconsItem
          service={`twitter`}
          linkTo={twitter.url}
          label='Stak on Twitter'
        />
    }
    {
      instagram &&
        <SocialIconsItem
          service={`instagram`}
          linkTo={instagram.url}
          label='Stak on Instagram'
        />
    }
  </Fragment>
)

export default SocialIconsView
