import React from 'react'

import View from './SocialIconsView'

const SocialIcons = ({ twitter, facebook, instagram }) => (
  <View
    twitter={twitter}
    facebook={facebook}
    instagram={instagram}
  />
)

export default SocialIcons
