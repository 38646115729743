import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import Navigation from 'Navigation'
import Search from 'Search'
import Drawer from 'Drawer'

import { ReactComponent as Logo } from 'assets/img/svg/logo.svg'

const HeaderView = ({ mainNavItems, sidebarNavItems, slug, title }) => (
  <header
    className='c-header'
  >
    <div
      className='c-header__logo c-site-logo'
    >
      <Link
        className='c-site-logo__link u-dis-ib'
        to='/'
        aria-label='Stak'
      >
        <Logo />
      </Link>
    </div>
    <div
      className='c-header__nav'
    >
      <Navigation
        items={mainNavItems}
        classNamePrefix='c-main-navigation'
      />
    </div>
    <div
      className='c-header__buttons u-ta-r'
    >
      <span
        className='c-header__buttons-item'
      >
        <Search />
      </span>
      <span
        className='c-header__buttons-item'
      >
        <Drawer>
          <Navigation
            items={mainNavItems}
            classNamePrefix='c-drawer-navigation'
          />
          <Navigation
            items={sidebarNavItems}
            classNamePrefix='c-drawer-navigation'
          />
        </Drawer>
      </span>
    </div>
  </header>
)

HeaderView.propTypes = {
  mainNavItems: PropTypes.array.isRequired,
  sidebarNavItems: PropTypes.array.isRequired
}

export default HeaderView
