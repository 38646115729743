import React from 'react'
import { Link } from 'gatsby'

import Navigation from 'Navigation'

const FooterBottom = ({ navItems }) => (
  <div
    className='c-footer-bottom'
  >
    <div
      className='o-container u-margin-bottom'
    >
      <div
        className='o-layout'
      >
        <div
          className='o-layout__item u-width-1/4@md u-margin-bottom-small u-margin-bottom-none@md'
        >
          <p
            className='u-fs-13 u-ta-c u-ta-l@md'
          >
            <Link to='/privacy-policy/' className='u-color-white'>
              Privacy Policy
            </Link>
          </p>
        </div>
        <div
          className='o-layout__item u-width-2/4@md u-margin-bottom-small u-margin-bottom-none@md'
        >
          <Navigation
            classNamePrefix='c-footer-navigation'
            menuClassName='o-list-inline o-list-inline--with-separator u-ta-c'
            items={navItems}
          />
        </div>
        <div
          className='o-layout__item u-width-1/4@md'
        >
          <p
            className='u-fs-13 u-ta-c u-ta-r@md'
          >
            Website by{` `}
            <a
              href='https://breaksagency.com'
              target='_blank'
              rel='noopener noreferrer'
              className='c-footer-navigation__menu-link u-color-white o-action-link'
            >
                Breaks
            </a>
          </p>
        </div>
      </div>
    </div>
    <div className='o-container'>
      <p
        className='u-fs-13 u-ta-c u-ta-l@md'
      >
        Copyright Stakhanov Industries Ltd {new Date().getFullYear()}. All rights reserved. Stakhanov Industries Ltd is registered in England & Wales. Company registration number 09184835.<br /> Registered address 18 Holly Road, Cove, Farnborough, Hants, GU14 0EA.
      </p>
    </div>
  </div>
)

export default FooterBottom
