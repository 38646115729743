import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import NavigationItem from './NavigationItem'

const NavigationView = ({ items, classNamePrefix, parentClassName, menuClassName }) => (
  <nav
    className={classNames(classNamePrefix, parentClassName)}
  >
    <ul
      className={`${classNamePrefix}__menu ${menuClassName}`}
    >
      {
        items.map(({ text, url }, key) =>
          <NavigationItem
            text={text}
            url={url}
            classNamePrefix={classNamePrefix}
            key={key}
          />
        )
      }
    </ul>
  </nav>
)

NavigationView.propTypes = {
  items: PropTypes.array.isRequired,
  classNamePrefix: PropTypes.string.isRequired,
  parentClassName: PropTypes.string,
  menuClassName: PropTypes.string
}

export default NavigationView
