import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import Header from 'Header'
import Footer from 'Footer'
import CookieConsent from 'CookieConsent'

const LayoutView = ({ children }) => (
  <Fragment>
    <Header />
    <main>
      { children }
    </main>
    <Footer />
    <CookieConsent />
  </Fragment>
)

LayoutView.propTypes = {
  children: PropTypes.node.isRequired
}

export default LayoutView
