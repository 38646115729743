import React, { useState, Fragment } from 'react'
// import PropTypes from 'prop-types'

import View from './DrawerView'
import Trigger from './DrawerTrigger'
import { HamburgerIcon, CloseIcon } from 'Icons'

const Drawer = ({ children }) => {
  const [isVisible, setVisible] = useState(false)

  const openDrawer = () => {
    setVisible(true)
  }

  const closeDrawer = () => {
    setVisible(false)
  }

  return (
    <Fragment>
      <Trigger
        handleClick={openDrawer}
      >
        <HamburgerIcon />
      </Trigger>
      <View
        isVisible={isVisible}
      >
        <Trigger
          handleClick={closeDrawer}
          className='c-drawer-trigger--close'
        >
          <CloseIcon />
        </Trigger>
        {children}
      </View>
    </Fragment>
  )
}

export default Drawer
